import React, { useRef, useEffect } from "react"
import { Link } from "gatsby"
import Image from "gatsby-image"

import SEO from "../components/seo"
import Footer from "../components/footer"

import Test2 from "../svg/text-2.svg"
import ogImageHomepage from "../images/og-image/og-image-homepage.png"
import { graphql } from "gatsby"

import AnimatedHeadline from "../components/AnimatedHeadline"
import Kontakt from "../components/kontakt"
const IndexPage = ({ data }) => {
  let headerText =
    "Digitale Agentur mit fundiertem Fachwissen in Design- und Entwicklung. Wir erstellen fertige Websites, beraten Sie in UX und UI-Themen und helfen ihnen Social Media richtig zu nutzen."
  let introSVG = useRef(null)

  function formatDate(date) {
    let day = date.getDay()
    let month = date.getMonth()
    let year = date.getFullYear()

    const options = { year: "numeric", month: "long", day: "numeric" }

    /*   console.log(date.toLocaleDateString('de-DE', options)); */
    return date.toLocaleDateString("de-DE", options)
  }

  return (
    <>
      <SEO
        title="Cuuno. Eine digitale Agentur."
        description="Digitale Agentur mit fundiertem Fachwissen in Design- und Entwicklung. Wir erstellen fertige Websites, beraten Sie in UX und UI-Themen und helfen ihnen Social Media richtig zu nutzen."
        image={ogImageHomepage}
        defaultTitle="Homepage - Cuuno"
        titleTemplate="Homepage"
      />
      <section className="cb-intro ">
        <div className="cb-container -h85">
          <div class="cb-intro-wrap">
            <div className="cb-intro-body">
              <div className="cb-intro-header">
                <AnimatedHeadline
                  scrollTo={"#ux"}
                  offset={-100}
                  headerText={headerText}
                  headerTextClass={"cb-text cb-intro-text"}
                  mobileText="Wir helfen ihnen das volle Potential aus ihrem Onlineauftritt zu schöpfen."
                  desktopText={[
                    "Wir helfen ihnen das ",
                    "volle Potential aus ihrem",
                    "Onlineauftritt zu schöpfen.",
                  ]}
                />
              </div>
            </div>

            <div className="cb-intro-svg" ref={el => (introSVG = el)}>
              {<Test2 />}
            </div>
          </div>
        </div>
      </section>

      <section id="ux" className="cb-categories -bg-blue">
        <div className="cb-container">
          <div data-aos="fade-up" className="cb-categories-grid">
            <div className="cb-categories-grid-col">
              <div className="cb-categories-img-container">
                <div className="cb-categories-img -ux-img">
                  <Image
                    fluid={data.ux.childImageSharp.fluid}
                    alt="UX-Process"
                  />
                </div>
              </div>
            </div>

            <div className="cb-categories-grid-col">
              <div class="cb-categories-grid-col-wrapper">
                <div className="cb-categories-header">
                  <div className="cb-header">
                    <h1>UX und UI</h1>
                  </div>
                </div>

                <div className="cb-categories-subheader">
                  <div className="cb-text">
                    <p>
                      Als zertifizierte UX-Professionals wissen wir was gute
                      Usability und User Experience ausmacht. Neben
                      Usability-Testing bieten wir Usability-Reviews an, um die
                      Nutzerfreundlichkeit ihrer Anwendung sicherzustellen.
                    </p>
                  </div>
                </div>
                <Link to="/leistungen" className="cb-categories-cta">
                  <div className="cb-button -color-bg-blue">
                    <p>Leistungen ansehen</p>
                    <span>
                      <svg
                        version="1.1"
                        viewBox="0 0 492 492"
                        style={{ enableBackground: "new 0 0 492 492" }}
                      >
                        <path
                          d="M464.344,207.418l0.768,0.168H135.888l103.496-103.724c5.068-5.064,7.848-11.924,7.848-19.124
			c0-7.2-2.78-14.012-7.848-19.088L223.28,49.538c-5.064-5.064-11.812-7.864-19.008-7.864c-7.2,0-13.952,2.78-19.016,7.844
			L7.844,226.914C2.76,231.998-0.02,238.77,0,245.974c-0.02,7.244,2.76,14.02,7.844,19.096l177.412,177.412
			c5.064,5.06,11.812,7.844,19.016,7.844c7.196,0,13.944-2.788,19.008-7.844l16.104-16.112c5.068-5.056,7.848-11.808,7.848-19.008
			c0-7.196-2.78-13.592-7.848-18.652L134.72,284.406h329.992c14.828,0,27.288-12.78,27.288-27.6v-22.788
			C492,219.198,479.172,207.418,464.344,207.418z"
                        />
                      </svg>
                    </span>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="cb-categories -websites">
        <div className="cb-container">
          <div data-aos="fade-up" className="cb-categories-grid -reverse">
            <div className="cb-categories-grid-col">
              <div className="cb-categories-img-container">
                <div className="cb-categories-img -website-img">
                  <Image
                    fluid={data.website.childImageSharp.fluid}
                    alt="Webseite Illustration"
                  />
                </div>
              </div>
            </div>

            <div className="cb-categories-grid-col">
              <div class="cb-categories-grid-col-wrapper">
                <div className="cb-categories-header">
                  <div className="cb-header">
                    <h1>Websites</h1>
                  </div>
                </div>

                <div className="cb-categories-subheader">
                  <div className="cb-text">
                    <p>
                      Wir designen um zu vereinfachen. Wir verfolgen eine
                      Ideologie der Qualität und Einfachheit, um langlebige
                      Lösungen zu erreichen. Unterstützt durch moderne
                      Technologien bauen wir Webseiten, die die Funktionalität
                      verbessern, zuverlässig bleiben und sich mit der Zeit
                      weiterentwickeln.
                    </p>
                  </div>
                </div>
                <Link to="/leistungen" className="cb-categories-cta">
                  <div className="cb-button -color-bg-blue">
                    <p>Leistungen ansehen</p>
                    <span>
                      <svg
                        version="1.1"
                        viewBox="0 0 492 492"
                        style={{ enableBackground: "new 0 0 492 492" }}
                      >
                        <path
                          d="M464.344,207.418l0.768,0.168H135.888l103.496-103.724c5.068-5.064,7.848-11.924,7.848-19.124
			c0-7.2-2.78-14.012-7.848-19.088L223.28,49.538c-5.064-5.064-11.812-7.864-19.008-7.864c-7.2,0-13.952,2.78-19.016,7.844
			L7.844,226.914C2.76,231.998-0.02,238.77,0,245.974c-0.02,7.244,2.76,14.02,7.844,19.096l177.412,177.412
			c5.064,5.06,11.812,7.844,19.016,7.844c7.196,0,13.944-2.788,19.008-7.844l16.104-16.112c5.068-5.056,7.848-11.808,7.848-19.008
			c0-7.196-2.78-13.592-7.848-18.652L134.72,284.406h329.992c14.828,0,27.288-12.78,27.288-27.6v-22.788
			C492,219.198,479.172,207.418,464.344,207.418z"
                        />
                      </svg>
                    </span>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="cb-categories -bg-blue">
        <div className="cb-container">
          <div data-aos="fade-up" className="cb-categories-grid">
            <div className="cb-categories-grid-col">
              <div className="cb-categories-img-container">
                <div className="cb-categories-img -social-img">
                  <Image
                    fluid={data.social.childImageSharp.fluid}
                    alt="Social-Media"
                  />
                </div>
              </div>
            </div>

            <div className="cb-categories-grid-col">
              <div class="cb-categories-grid-col-wrapper">
                <div className="cb-categories-header">
                  <div className="cb-header">
                    <h1>Social Media</h1>
                  </div>
                </div>

                <div className="cb-categories-subheader">
                  <div className="cb-text">
                    <p>
                      Präsenz und Engagement in sozialen Netzwerken ist
                      unverzichtbar für den Erfolg im Web. Wir entwickeln für
                      Sie und Ihr Produkt die passenden Strategie aus Tools und
                      Maßnahmen.
                    </p>
                  </div>
                </div>
                <Link to="/leistungen" className="cb-categories-cta">
                  <div className="cb-button -color-bg-blue">
                    <p>Leistungen ansehen</p>
                    <span>
                      <svg
                        version="1.1"
                        viewBox="0 0 492 492"
                        style={{ enableBackground: "new 0 0 492 492" }}
                      >
                        <path
                          d="M464.344,207.418l0.768,0.168H135.888l103.496-103.724c5.068-5.064,7.848-11.924,7.848-19.124
			c0-7.2-2.78-14.012-7.848-19.088L223.28,49.538c-5.064-5.064-11.812-7.864-19.008-7.864c-7.2,0-13.952,2.78-19.016,7.844
			L7.844,226.914C2.76,231.998-0.02,238.77,0,245.974c-0.02,7.244,2.76,14.02,7.844,19.096l177.412,177.412
			c5.064,5.06,11.812,7.844,19.016,7.844c7.196,0,13.944-2.788,19.008-7.844l16.104-16.112c5.068-5.056,7.848-11.808,7.848-19.008
			c0-7.196-2.78-13.592-7.848-18.652L134.72,284.406h329.992c14.828,0,27.288-12.78,27.288-27.6v-22.788
			C492,219.198,479.172,207.418,464.344,207.418z"
                        />
                      </svg>
                    </span>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="cb-article">
        <div class="cb-container">
          <div data-aos="fade-up" class="cb-article-grid">
            <div class="cb-article-grid-col -left">
              <div class="cb-header">
                <h1>Neuigkeiten</h1>
              </div>
            </div>
            <div class="cb-article-grid-col -right">
              {data.allWordpressPost.edges.map(({ node: post }) => {
                return (
                  <div key={post.id} class="cb-article-item">
                    <Link
                      to={`/blog/${post.slug}`}
                      aria-label={post.slug}
                      class="cb-article-item-heading"
                    >
                      <h1>{post.title}</h1>
                    </Link>

                    <div class="cb-article-item-subheading">
                      <span>{formatDate(new Date(post.date))} -</span>{" "}
                      <span>{post.categories[0].name}</span>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </section>

      <Kontakt />

      <Footer />
    </>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query LandingpageQuery {
    allWordpressPost {
      edges {
        node {
          id
          title
          date(formatString: "MMMM DD, YYYY")
          slug
          categories {
            id
            name
          }
        }
      }
    }

    ux: file(relativePath: { eq: "ux.png" }) {
      childImageSharp {
        fluid(maxWidth: 1500, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    website: file(relativePath: { eq: "website.png" }) {
      childImageSharp {
        fluid(maxWidth: 1500, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    social: file(relativePath: { eq: "isoSocial.png" }) {
      childImageSharp {
        fluid(maxWidth: 1500, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
